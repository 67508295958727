<template>
  <div class="order">
    <div class="order__header">
      <div class="order__header__info">
        <div>
          <router-link
            :to="{ name: 'order-id', params: { id: orderId } }"
            class="order__title"
          >
            Order {{ order.name }}
          </router-link>
        </div>
        <div>
          {{ amount }}
        </div>
      </div>
      <div class="order__header__status">
        <div class="order__header__status__chip">
          <div
            class="order__header__status__chip__dot"
            :class="{ [status.color]: true }"
          />
          {{ status.text }}
        </div>
        <div class="order__header__status__date">
          Update, {{ date }}
        </div>
      </div>
    </div>
    <div class="order__body">
      <div class="order__cart">
        <div>Cart</div>
        <div>{{ order.subtotalLineItemsQuantity }} Items</div>
      </div>
      <div class="order__content">
        <div
          v-for="(item, index) in orderItems"
          :key="index"
          class="order__image-container"
        >
          <img
            :src="item.image ? item.image.url : ''"
            :alt="item.variantTitle"
            class="order__image"
          />
        </div>
      </div>
    </div>

    <div class="order__footer">
      <button
        class="order__button"
        @click="$emit('buy:again', order.id)"
      >
        Buy Again
      </button>

      <router-link
        :to="`/account/orders/${orderId}`"
        class="order__button"
      >
        Details
      </router-link>
    </div>
  </div>
</template>

<script>
  import vClickOutside from 'v-click-outside';
  import { formatDate } from '@/shared/helpers/date';

  export default {
    name: 'OrderItemComponent',
    directives: {
      clickOutside: vClickOutside.directive,
    },
    props: {
      order: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        showMore: false,
      };
    },
    computed: {
      amount() {
        const customTaxAttribute = this.order.customAttributes.find(a => a.key === 'tax');
        const taxAmount = customTaxAttribute?.value || 0;
        const amount = (+this.order.totalPriceSet.shopMoney.amount) + (+taxAmount);

        return `$${Number(amount).toFixed(2)}`;
      },

      date() {
        return formatDate(this.order.updatedAt);
      },

      orderId() {
        const parseString = this.order.id.split('/');
        return parseString[parseString.length - 1];
      },

      orderItems() {
        const items = [...this.order.lineItems.edges].map((item) => ({
          ...item.node,
        }));

        return items;
      },
      status() {
        if (!this.order.fulfillments.length) {
          return { text: 'Submitted', color: 'yellow' };
        }

        if (this.order.fulfillments.length === 1) {
          switch (this.order.fulfillments[0].displayStatus) {
            case 'FULFILLED':
              return { text: 'Confirm', color: 'yellow' };
            case 'IN_TRANSIT':
              return { text: 'On its way', color: 'yellow' };
            case 'OUT_FOR_DELIVERY':
              return { text: 'Out for delivery', color: 'yellow' };
            case 'DELIVERED':
              return { text: 'Delivered', color: 'green' };
            case 'CANCELED':
              return { text: 'Canceled', color: 'red' };
          }
          return { text: 'Multiple Shipments', color: 'green' };
        }

        if (this.order.fulfillments.every((f) => f.displayStatus === 'DELIVERED')) {
          return { text: 'Delivered', color: 'green' };
        }

        return { text: 'Multiple Shipments', color: 'green' };
      },
    },
    methods: {
      handleShowMore() {
        this.showMore = true;
      },
      handleHideMore() {
        this.showMore = false;
      },
      handleToggleMore() {
        this.showMore = !this.showMore;
      },
    },
  };
</script>

<style scoped lang="scss">
  .yellow {
    background-color: #F6D10D;
  }
  .green {
    background-color: #4FE09D;
  }
  .red {
    background-color: #F02F30;
  }
  .order {
    color: #343434;
    border: 1px solid #edf1f4;
    border-radius: 10px;
    overflow: auto;

    &__header {
      display: flex;
      justify-content: space-between;
      background-color: #FAFAFB;
      padding: 12px 16px;
      border-bottom: 1px solid #edf1f4;

      &__info {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        margin-top: 7px;
      }

      &__status {
        font-size: 13px;
        &__chip {
          display: flex;
          align-items: center;
          padding: 7px 10px;
          margin-bottom: 5px;
          border: 1px solid #E7EBF1;
          border-radius: 5px;
          font-weight: 400;
          margin-left: auto;
          width: max-content;
          &__dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
        &__date {
          color: #B6C0D3;
          display: flex;
          justify-content: end;
        }
      }
    }

    &__title {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      line-height: 20px;
      color: #343434;
    }

    &__dots {
      padding: 0 5px;
      position: relative;
      cursor: pointer;
    }

    &__return {
      padding: 5px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      right: 20px;
      width: 150px;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      border: 1px solid #edeff5;
      border-radius: 5px;
      background: #fff;

      &--hide {
        display: none;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      line-height: 22px;
    }

    &__price {
      font-size: 15px;
    }

    &__date {
      font-size: 14px;
      color: #b8bbc9;
    }

    &__body {
      display: flex;
      flex-direction: column;
      padding: 16px;
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 4px;
    }

    &__cart {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      &>div:first-child {
        margin-right: 5px;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
      }
      &>div:last-child {
        color: #B6C0D3;
        font-size: 14px;
        line-height: 22px;
      }

    }

    &__image-container {
      height: auto;
      overflow: hidden;
      img {
        border-radius: 10px;
        border: 1px solid #E7EBF1;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__show-details {
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #343434;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #edf1f4;
      padding: 12px 16px;
    }

    &__button {
      width: 100%;
      padding: 8px 15px;
      border-radius: 7px;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #343434;
      text-align: center;
      cursor: pointer;
      outline: none;
      border: 1px solid #E7EBF1;
      &:active {
        border: 1px solid #21a66e;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
</style>