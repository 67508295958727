export default `
query getOrders($query: String!, $count: Int!, $page: String) {
  orders(first: $count, after: $page, query: $query, sortKey: CREATED_AT, reverse: true) {
    edges {
      node {
        id
        name
        createdAt
        updatedAt
        subtotalLineItemsQuantity
        customAttributes {
          key
          value
        }
        totalPriceSet {
          shopMoney {
            currencyCode
            amount
          }
        }
        totalDiscountsSet {
          shopMoney {
            currencyCode
            amount
          }
        }
        lineItems(first: 5) {
          edges {
            node {
              variantTitle
              id
              image {
                url
              }
            }
          }
        }
        fulfillments(first: 10) {
          id
          displayStatus
        }
      }
      cursor
    }
    pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
    }
  }
}
`;
