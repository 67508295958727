<template>
  <orders-container/>
</template>

<script>
  import OrdersContainer from './orders.container';

  export default {
    name: 'OrdersView',
    components: {
      OrdersContainer,
    },
  }
</script>