import makeECommerceRequest from 'src/core/http/e-commerce.client';
import getOrdersQuery from './queries/get-orders.query';
import getOrderLineItemsQuery from './queries/get-order-line-items.query';

/**
 * @param {String} email:
 * @param {Object} options:
 * @param {Number} options.count:
 * @param {String} options.page:
 */
const getOrders = async (email, providerId, options) => {
  const response = await makeECommerceRequest(getOrdersQuery, {
    query: `email:'${email}' status:any tag:'int:store:${providerId}'`,
    ...options,
  });

  return response;
};

/**
 * @param {Object} payload:
 * @param {String} options.id:
 */
const getOrderLineItems = (payload) => makeECommerceRequest(getOrderLineItemsQuery, payload);

export default {
  getOrders,
  getOrderLineItems,
};
