<template>
  <div class="orders">
    <account-page-title title="My Orders" class="orders__title"/>

    <!-- <div class="orders__title">My Orders</div>

    <router-link to="/account" class="orders__title orders__title--mobile">
      <img
        :src="require('@/assets/icons/account/left-arrow-black.svg')"
        alt="return"
        class="orders__title-arrow"
      />

      My Orders
    </router-link> -->

    <div class="orders__list">
      <div v-for="(order, index) of orders" :key="index" class="orders__item">
        <order-item v-if="order" :order="order.node" @buy:again="buyAgain" />
      </div>

      <observer @intersect="onIntersect" />
    </div>

    <error-message-component
      v-if="!orders.length && !loading"
      title="Nothing to see here yet"
      subtitle="Start shopping now to fill it up with the latest products and deals!"
      class="mx-auto"
    />

    <loader v-if="loading" color="#343434" position="relative" class="mt-5" />
  </div>
</template>

<script>
  import { amsClient } from 'src/service/ams';

  import OrderItem from './components/order-item.component';
  import Loader from '@/components/loader/loader';
  import Observer from '@/components/Observer';
  import AccountPageTitle from '../../components/account-page-title.component';
  import ErrorMessageComponent from '@/shared/components/error-message.component';

  import OrdersDataService from './services/orders-data.service';
  import SharedCartDataService from '@/shared/services/cart/cart-data.service';

  import Auth from '@/service/authService';
  import Config from '@/service/config';

  const OrdersService = new OrdersDataService();
  const CartService = new SharedCartDataService();

  export default {
    name: 'OrdersContainer',
    components: {
      Loader,
      OrderItem,
      Observer,
      AccountPageTitle,
      ErrorMessageComponent,
    },
    data() {
      return {
        loading: false,
        pagination: {
          count: 20,
          page: '',
        },
        pageInfo: null,
        orders: [],
        store: {},
      };
    },

    computed: {
      user() {
        return Auth.get('user');
      },
      cartId() {
        if (Auth.get('user') && !Auth.get('user')?.guest) {
          return Auth.get('user').cart;
        }

        return localStorage.getItem('cartId');
      },
    },

    async mounted() {
      this.$bus.$emit('updateTitle', 'My Orders');

      await this.fetchOrders();
    },

    methods: {
      async fetchOrders() {
        this.loading = true;

        this.setCountForDifferentWindowSize();

        const email = this.user.privateId;

        const options = {
          count: this.pagination.count,
          ...(this.pagination.page ? { page: this.pagination.page } : {}),
        };

        try {
          const response = await OrdersService.getOrders(
            email,
            Config.get('provider_id') || 'gjukfabvy32xq3hk',
            options,
          );
          // const response = await OrdersService.getOrders(email, 'paapc9jyxrdsvj88', options);

          this.orders = [...this.orders, ...response.orders.edges];

          const lastOrder = this.orders[this.orders.length - 1];

          this.pagination = {
            ...this.pagination,
            ...response.orders.pageInfo,
            page: lastOrder.cursor,
          };
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },

      async onIntersect() {
        if (!this.pagination?.hasNextPage) {
          return;
        }

        await this.fetchOrders();
      },

      setCountForDifferentWindowSize() {
        if (window.innerWidth <= 1800 && window.innerWidth > 1250) {
          this.pagination.count = 30;
        } else if (window.innerWidth <= 1250 && window.innerWidth > 800) {
          this.pagination.count = 20;
        } else if (window.innerWidth <= 800) {
          this.pagination.count = 12;
        } else {
          this.pagination.count = 40;
        }
      },
      async buyAgain(orderId) {
        this.loading = true;

        try {
          const response = await OrdersService.getOrderLineItems(orderId);
          const lineItems = response.node.lineItems.edges;

          const payload = {
            cartId: this.cartId,
            items: [],
          };

          lineItems.forEach(({ node }, index) => {
            const store = node.customAttributes.find(
              (attr) => attr.key === '__store',
            );

            payload.items.push({
              merchandiseId: node.variant.id,
              quantity: node.quantity,
              attributes: [
                {
                  key: '__store',
                  value: store.value,
                },
                {
                  key: '__ref',
                  value: btoa(
                    JSON.stringify({
                      platform: 'web|android|ios|youtube',
                      item: 'itemId',
                      source: 'vod|live',
                    }),
                  ),
                },
              ],
            });
          });

          const res = await CartService.addALotOfItemsToCart(payload);

          this.$store.commit(
            'checkout/setCart',
            res?.cartLinesAdd?.cart || null,
          );
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .orders {
    margin-bottom: 60px;
    color: #343434;

    &__title {
      margin-bottom: 20px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      gap: 20px;
    }
  }
</style>
