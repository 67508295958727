import apiService from '../api';

class OrdersService {
  /**
   * @param {String} email:
   * @param {Object} options:
   * @param {Number} options.count:
   * @param {String} options.page:
   */
  async getOrders(email, providerId, options) {
    const response = await apiService.getOrders(email, providerId, options);

    return response;
  }

  getOrderLineItems(id) {
    return apiService.getOrderLineItems({ id });
  }
}

export default OrdersService;
