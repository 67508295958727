export default `query getOrder($id: ID!) {
  node(id: $id) {
    ... on Order {
      lineItems(first: 50) {
        edges {
          node {
            customAttributes {
              key
              value
            }
            quantity
            variant {
              id
            }
          }
        }
      }
    }
  }
}
`;
